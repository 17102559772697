import {useState, useEffect, useRef, useCallback} from 'react'

import loadImage from './loadImage'

const cache = new Map()

export const Status = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}

export default function useImage(src: string) {
  const cachedImg = cache.get(src)
  const initialState = cachedImg ? Status.LOADED : Status.LOADING
  const [status, setStatus] = useState(initialState)
  const mounted = useRef(false)

  const load = useCallback(async () => {
      if (!src || status === Status.LOADED) return
      mounted.current = true

      try {
        await loadImage(src)
        if (!mounted.current) return

        cache.set(src, src)
        setStatus(Status.LOADED)
      } catch (error) {
        if (!mounted.current) return

        cache.delete(src)
        setStatus(Status.FAILED)
      }
      return () => {
        mounted.current = false
      }
    },
    [src, status]);


  useEffect(() => {load()}, [load]);

  return [cachedImg, status]
}