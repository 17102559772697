import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ApolloProvider} from "@apollo/client";
import client from "./apollo-client";

import './fonts/fonts.scss';

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
