const loadImage = (url: string, crossOrigin?: string) => {
  const image = new Image()

  // Support cross origin requests
  if (crossOrigin) image.crossOrigin = crossOrigin

  return new Promise((resolve, reject) => {
    // Load Handler
    const loaded = (event: any) => {
      // Cleanup our image element, we no longer need it
      unbindEvents(image)
      // Fulfill our promise with the event image element, even in older browsers
      resolve(event.target || event.srcElement)
    }

    // Error Handler
    const errored = (error: any) => {
      // Cleanup our image element, we no longer need it
      unbindEvents(image)
      // Forward our error to the user
      reject(error)
    }

    // Set our handlers
    image.onload = loaded
    image.onerror = errored
    image.onabort = errored

    // Tell the browser we are ready to begin downloading
    image.src = url
  })
}

function unbindEvents(image: HTMLImageElement) {
  image.onload = null
  image.onerror = null
  image.onabort = null

  try {
    // @ts-ignore
    delete image.src
  } catch (e) {}
}

export default loadImage;