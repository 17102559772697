import React, {useCallback, useMemo, useState} from 'react';
import useImage from './helpers/useImage';
import ChuckImageSource from './resources/1st.gif';
import Chuck2ImageSource from './resources/2nd.gif';
import StarsImageSource from './resources/constellations.gif';
import {gql, useQuery} from "@apollo/client";
import cn from 'classnames';

import styles from './styles.module.scss';

const GET_PHRASES = gql`
    query getPhrases {
        phrases {
            id
            text
        }
    }
`;

function App() {
  const [isTouched, setIsTouched] = useState(false);
  const [phraseNum, setPhraseNum] = useState(0);
  const [isChuckActive, setIsChuckActive] = useState(false);
  const [chuckSrc, isChuckLoading] = useImage(ChuckImageSource);
  const [chuckSrc2, isChuck2Loading] = useImage(Chuck2ImageSource);
  const [starsSrc, isStarsLoading] = useImage(StarsImageSource);
  const { loading, data } = useQuery(GET_PHRASES);

  const isPageLoading = useMemo(() => {
    return isChuckLoading === 'loading' || isStarsLoading === 'loading' || loading || isChuck2Loading === 'loading';
  }, [isChuck2Loading, isChuckLoading, isStarsLoading, loading]);

  const phrase = useMemo(() => {
    if (!data) {
      return null;
    }

    const list = data?.phrases;

    return list?.[phraseNum]?.text;
  }, [data, phraseNum]);

  const handleClick = useCallback(() => {
    const list = data?.phrases;
    if (list) {
      const nextNum = phraseNum + 1;
      if (nextNum >= list.length - 1) {
        setPhraseNum(0);
      } else {
        setPhraseNum(nextNum);
      }
    }
    setIsTouched(true);
  }, [data?.phrases, phraseNum]);

  const activateChuck = useCallback(() => {
    setIsChuckActive(true);
  }, []);



  if (isPageLoading) {
    return <p>'loading'</p>;
  }

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.mainWrap}>
        <p key={phrase} className={styles.phrase}>
          {phrase}
        </p>
        <div className={styles.chuckWrap}>
          <div className={styles.chuckImg}>
            <img className={styles.chuck} alt="chuck" src={!isChuckActive ? chuckSrc : chuckSrc2} />
            <div className={styles.chuckActivator} onClick={activateChuck} />
          </div>
        </div>
        <p className={cn(styles.note, { [styles.animated]: !isTouched })}>
          <span style={{ opacity: isTouched ? 0 : 1 }}>Нажмите в любом месте, чтобы увидеть другой факт</span>
        </p>
      </div>
      <img className={styles.stars} alt="star" src={starsSrc} />
    </div>
  );
}

export default App;
