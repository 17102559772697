import { ApolloClient, InMemoryCache } from '@apollo/client';

function shuffle(arr: Array<Object>){
  let j, temp;
  for(let i = arr.length - 1; i > 0; i--){
    j = Math.floor(Math.random()*(i + 1));
    temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

const client = new ApolloClient({
  uri: 'https://admin.chuck-facts.com/api/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          phrases: {
            merge(_, data) {
              return shuffle([...data]);
            },
          },
        },
      },
    },
  }),
});

export default client;